/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'umbrella-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M8 0a.5.5 0 01.5.5v.514C12.625 1.238 16 4.22 16 8c0 0 0 .5-.5.5-.149 0-.352-.145-.352-.145l-.004-.004-.025-.023a3.5 3.5 0 00-.555-.394A3.17 3.17 0 0013 7.5c-.638 0-1.178.213-1.564.434a3.5 3.5 0 00-.555.394l-.025.023-.003.003s-.204.146-.353.146-.352-.145-.352-.145l-.004-.004-.025-.023a3.5 3.5 0 00-.555-.394 3.3 3.3 0 00-1.064-.39V13.5H8h.5v.039l-.005.083a3 3 0 01-.298 1.102 2.26 2.26 0 01-.763.88C7.06 15.851 6.587 16 6 16s-1.061-.148-1.434-.396a2.26 2.26 0 01-.763-.88 3 3 0 01-.302-1.185v-.025l-.001-.009v-.003s0-.002.5-.002h-.5V13a.5.5 0 011 0v.506l.003.044a2 2 0 00.195.726c.095.191.23.367.423.495.19.127.466.229.879.229s.689-.102.879-.229c.193-.128.328-.304.424-.495a2 2 0 00.197-.77V7.544a3.3 3.3 0 00-1.064.39 3.5 3.5 0 00-.58.417l-.004.004S5.65 8.5 5.5 8.5s-.352-.145-.352-.145l-.004-.004a3.5 3.5 0 00-.58-.417A3.17 3.17 0 003 7.5c-.638 0-1.177.213-1.564.434a3.5 3.5 0 00-.58.417l-.004.004S.65 8.5.5 8.5C0 8.5 0 8 0 8c0-3.78 3.375-6.762 7.5-6.986V.5A.5.5 0 018 0"/>',
    },
});
